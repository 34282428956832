import * as React from 'react';
import {useState} from 'react';
import Layout from '../components/general/layout';
import Container from '../components/general/container';
import {graphql} from 'gatsby';
import useIntl from '../helpers/useIntl';
import CardGrid from '../components/general/card-grid';
import {Radio} from 'antd';
import ProjectPageStyle from '../less/project-page.module.less';
import SelectStyle from '../less/select.module.less';
import {Router, useLocation} from "@reach/router"
import * as queryString from 'query-string';
import Select from 'react-select'
import ProjectCard from "../components/general/project-card";

const Showcases = ({data, navigate, pageContext}) => {
    const location = useLocation();
    const intl = useIntl();

    const [radioButtonsFocussed, setRadioButtonsFocussed] = useState(false);
    const [resultTitle, setResultTitle] = useState(
      pageContext.isExpenses ?
        `${intl.formatMessage({id: 'general_expenses'})} - ${intl.formatMessage({id: 'projects_all'})}` :
        `${intl.formatMessage({id: 'general_revenue'})} - ${intl.formatMessage({id: 'projects_all'})}`
    );

    if (typeof window !== `undefined`) {
      document.addEventListener('keyup', event => {
        if (event.isComposing || event.keyCode === 229 || event.key !== 'Tab') {
          return;
        }
        let focussedElementId = document.activeElement.id;
        setRadioButtonsFocussed(focussedElementId === 'type1' || focussedElementId === 'type2');
      });
    }

    const projects = data.allBudgetShowcase.edges;
    const uniqueProjectIds = [...new Set(projects.map(item => item.node.id))];

    function getProjects(selectedFilter) {
      const projectList = [];
      uniqueProjectIds.forEach((uniqueProjectId) => {
        const categories = projects
          .filter(project => project.node.id === uniqueProjectId)
          .filter(project => {
            if (!selectedFilter) {
              return true;
            }

            return project.node.fields.budgetShowcasePosts
              .filter(budgetShowcasePost => (
                  intl.translate(budgetShowcasePost.fields.category, 'label') === selectedFilter.label
                )
              ).length
          });

        if (categories.length === 0) {
          return;
        }

        projectList.push({
          excerpt_fr: categories[0].node.excerpt_fr,
          excerpt_nl: categories[0].node.excerpt_nl,
          featuredImg: categories[0].node.featuredImg,
          id: categories[0].node.id,
          slug: categories[0].node.slug,
          title_fr: categories[0].node.title_fr,
          title_nl: categories[0].node.title_nl,
          categories: categories[0].node.fields.budgetShowcasePosts
        })
      })
      return projectList;
    }

    const [filteredProjects, setFilteredProjects] = useState(getProjects());

    const selectOptions = [];
    if (projects) {
      projects.forEach((project, i) => {
        project.node.fields.budgetShowcasePosts.forEach((budgetShowcasePost, j) => {
          const label = intl.translate(budgetShowcasePost.fields.category, 'label')
          if (selectOptions.findIndex((selectOption) => selectOption.label === label) === -1) {
            selectOptions.push({
              label: label,
              value: i + j + '',
            })
          }
        })
      })
    }
    selectOptions.sort(function (a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    });

    const selectStyles = {
      multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: 'var(--color-primary)',
        color: 'white',
        borderRadius: '5px',
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        color: 'white',
      }),
      multiValueRemove: (provided, state) => ({
        backgroundColor: 'var(--color-primary)',
        display: 'flex',
        alignItems: 'center',
        padding: '2px 3px 0',
        borderRadius: '5px',
      }),
      control: (provided, state) => ({
        display: 'flex',
        border: '1px solid hsl(0,0%,80%)',
        borderRadius: '5px',
        outline: state.isFocused ? '-webkit-focus-ring-color auto 5px' : 'none',
      }),
    };

    const breadcrumbs = [{
      label: intl.formatMessage({id: 'detail_projects'}),
      url: '/showcases',
    }];

    function setFilter(selectedFilter) {
      if (!selectedFilter) {
        navigate(`./`)
        setFilteredProjects(getProjects(selectedFilter));
        intl.formatMessage({id: 'projects_all'})
        setResultTitle(pageContext.isExpenses ?
          `${intl.formatMessage({id: 'general_expenses'})} - ${intl.formatMessage({id: 'projects_all'})}` :
          `${intl.formatMessage({id: 'general_revenue'})} - ${intl.formatMessage({id: 'projects_all'})}`)
        return;
      }

      navigate(`?filter=${selectedFilter.value}`);
      setFilteredProjects(getProjects(selectedFilter));
      setResultTitle(pageContext.isExpenses ?
        `${intl.formatMessage({id: 'general_expenses'})} - ${selectedFilter.label}` :
        `${intl.formatMessage({id: 'general_revenue'})} - ${selectedFilter.label}`)
    }

    function getFilterFromURL() {
      const selectedFilter = queryString.parse(location.search)['filter'];
      const mappedSelectOptions = selectOptions.map((selectOption) => selectOption.value);
      return selectOptions[mappedSelectOptions.indexOf(selectedFilter)];
    }


    return (
      <Layout heroBgColor={'#DFECF0'}
              isMarked={false}
              heroTitle={intl.formatMessage({id: 'projects_hero_title'})}
              heroTextColor={'#142A7F'}
              heroSize={'small'}
              showBreadcrumbs={true}
              breadcrumbs={breadcrumbs}>
        <Container size={'small'}>
          <section>
            <h3
              className={"visually-hidden"}>{intl.formatMessage({id: 'projects_filters_title'})}</h3>
            <div className={ProjectPageStyle.filters}>
              <div className={ProjectPageStyle.left}>
                <h4>{intl.formatMessage({id: 'projects_filters_inoutlabel'})}</h4>
                <Radio.Group
                  defaultValue={pageContext.isExpenses === false ? 'revenue' : 'expenses'}
                  value={pageContext.isExpenses === false ? 'revenue' : 'expenses'}
                  buttonStyle="solid"
                  size="medium"
                  onChange={(e) => navigate(`../${e.target.value}/`)}>
                  <Radio.Button
                    value={'revenue'}>{intl.formatMessage({id: 'projects_filters_type_revenuelabel'})}</Radio.Button>

                  <Radio.Button
                    value={'expenses'}>{intl.formatMessage({id: 'projects_filters_type_expenselabel'})}</Radio.Button>
                </Radio.Group>
              </div>

              <div className={ProjectPageStyle.right}>
                <h4>{intl.formatMessage({id: 'projects_filters_domains'})}</h4>
                <Select isClearable={true}
                        className={SelectStyle.wrapper}
                        placeholder={intl.formatMessage({id: 'projects_filters_category_placeholder'})}
                        noOptionsMessage={() => intl.formatMessage({id: 'projects_filters_category_nooption'})}
                        defaultValue={getFilterFromURL()}
                        styles={selectStyles}
                        options={selectOptions}
                        onChange={(value) => setFilter(value)}>
                </Select>
              </div>
            </div>

            <h3 style={{textTransform: 'capitalize'}}>{resultTitle}</h3>

            <CardGrid>
              {filteredProjects.map((project, index) => (
                <ProjectCard
                  key={index}
                  title={intl.translate(project, "title")}
                  description={intl.translate(project, 'excerpt')}
                  slug={project.slug}
                  categories={project.categories}
                  thumbnail={project.featuredImg}/>
              ))}
            </CardGrid>
          </section>
        </Container>
        <Router>
          <Showcases path=":type"/>
        </Router>
      </Layout>
    );
  }
;

export const query = graphql`
  query($isExpenses: Boolean!) {
    allBudgetShowcase(filter: {fields: {budgetShowcasePosts: {elemMatch: {fields: {budget: {expenses: {eq: $isExpenses}}}}}}}) {
      edges {
        node {
          id
          slug
          title_fr
          title_nl
          excerpt_nl
          excerpt_fr
          featuredImg {
            mobile: childImageSharp {
              fluid(maxWidth: 725) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            desktop: childImageSharp {
              fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fields {
            budgetShowcasePosts {
              fields {
                budget {
                  expenses
                }
                category {
                  label_nl
                  label_fr
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Showcases;
